import { Container, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ImgMobile from "../../../../media/landing-pages/lp_javassript-mobile.png";
import ImgDesktop from "../../../../media/landing-pages/lp_javassript-desktop.png";

const UseStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(6, 1, 0, 1)
    },
    description: {
        padding: theme.spacing(3, 0, 4, 0)
    },
    imgCommon: {
        maxWidth: "1024px",
        margin: "0 auto",
        position: "relative",
        // minHeight: "500px",
        overflow: "hidden",
        objectFit: "cover",
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
}))

export default function JavaSriptIntro ({data}){
    const classes = UseStyles();
    const desktopViewport = useMediaQuery("(min-width:768px)");
    return(
        <section className={classes.root} id="javascript">
            <Container>
                <Typography variant="h4" color="primary" align="right" className={classes.title}>{data.title}</Typography>
                <Typography variant="body1" align="justify" className={classes.description}>{data.description}</Typography>
                <div className={classes.imgCommon}>
                    <img
                        src={
                        desktopViewport
                            ? ImgDesktop
                            : ImgMobile
                        }
                        alt="backgroundImage"
                        className={classes.img}
                    />
                </div>
            </Container>
        </section>
    )
}