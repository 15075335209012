import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "relative",
    left: theme.spacing(-1),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#fff",
  },
  number: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFB6CA",
    padding: theme.spacing(4),
    borderRadius: "50%",
    position: "relative",
    zIndex: "1",
  },
  name: {
    position: "absolute",
    left: theme.spacing(5),
    backgroundColor: "#FF89A4",
    padding: theme.spacing(1, 9, 1, 5),
    "&:before": {
      content: `""`,
      position: "absolute",
      backgroundColor: "#fff",
      top: theme.spacing(-1),
      right: "-20%",
      height: theme.spacing(7),
      width: theme.spacing(8),
      zIndex: 2,
      transform: "skew(50deg)",
    },
  },
}));

export default function TitleLeft(props) {
  const classes = useStyles();
  return (
    <Typography component="h3" variant="h6" className={classes.title}>
      <span className={classes.number}>{props.title}</span>
      <span className={classes.name}> {props.label}</span>
    </Typography>
  );
}
