import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Image from "../../../common/Image";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  advantages: {
    textAlign: "center",
    margin: "0 auto",
    paddingBottom: theme.spacing(8),
  },
  advantages_title: {
    padding: theme.spacing(6, 0, 5, 0),
    color: "#488be9",
  },
  advantages_items: {
    margin: "0 auto",
    padding: theme.spacing(0, 1, 0, 1),
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      // marginLeft: theme.spacing(-3),
    },
  },
  item: {
    height: "100%",
    position: "relative",
    "&:not(:last-of-type)": {
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    "&:not(:last-of-type):before": {
      content: `''`,
      width: "50%",
      height: "1px",
      left: "50%",
      bottom: "0",
      position: "absolute",
      backgroundColor: "#FDE313",
      transform: "translateX(-50%)",
    },
    [theme.breakpoints.up("md")]: {
      "&:not(:last-of-type):before": {
        display: "none",
      },
      width: "33.33%",
      margin: theme.spacing(0, 3, 0, 3),
      paddingBottom: theme.spacing(0),
    },
  },
  item_icon: {
    width: " 120px",
    height: " 120px",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
      margin: "auto",
    },
  },
  item_title: {
    margin: "20px 0",
    opacity: "0.8",
  },
  item_description: {
    textAlign: "justify",
    opacity: "0.8",
    margin: "auto",
    [theme.breakpoints.up("sm")]:{
      width: "70%",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]:{
      width: "100%",
      textAlign: "justify",
    }
  },
}));

export default function Advantages({ data }) {
  const classes = useStyles();
  return (
    <section id="advantages">
      <Container className={classes.advantages}>
        <Typography variant="h4" className={classes.advantages_title}>
          {data.title}
        </Typography>

        <div className={classes.advantages_items}>
          {data.items.map((item, index) => (
            <div className={classes.item}>
              <Image className={classes.item_icon} {...item.image} />
              <Typography variant="h5" className={classes.item_title}>
                {item.title}
              </Typography>
              <Typography variant="body1" className={classes.item_description}>
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}
