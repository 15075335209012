import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LogoWorld from "../../../../media/common/world-logo.png";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ALink from "../../../common/Alink";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    background: "rgb(96,159,254)",
    background:
      "linear-gradient(90deg, rgba(96,159,254,1) 0%, rgba(0,77,170,1) 100%)",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    justifyContent: "space-between",
  },
  toolbar: {
    minHeight: "64px",
  },
  logo: {
    marginTop: 5,
    marginLeft: -5,
    maxWidth: 70,
  },
  menuheader: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  title: {
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing(12.5),
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(7),
      },
    },
  },

  link: {
    color: "#fff",
    cursor: "pointer",
    "&:hover": {
      color: "#fde314",
    },
  },
}));
export default function NavBar() {
  const classes = useStyles();

  return (
    <header className={classes.root}>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <div>
            <ALink to="/">
              <img src={LogoWorld} alt="" className={classes.logo} />
            </ALink>
          </div>
          <div className={classes.menuheader}>
            <Typography variant="h6" component="h2" className={classes.title}>
              <Link
                to="header"
                spy={true}
                smooth={true}
                offset={-70}
                className={classes.link}
              >
                Top
              </Link>
            </Typography>
            <Typography variant="h6" component="h2" className={classes.title}>
              <Link
                to="javascript"
                spy={true}
                smooth={true}
                offset={-65}
                className={classes.link}
              >
                JavaScript
              </Link>
            </Typography>

            <Typography variant="h6" component="h2" className={classes.title}>
              <Link
                to="news"
                spy={true}
                smooth={true}
                offset={-65}
                className={classes.link}
              >
                Tin tức
              </Link>
            </Typography>

            <Typography variant="h6" component="h2" className={classes.title}>
              <Link
                to="advantages"
                spy={true}
                smooth={true}
                offset={-67}
                className={classes.link}
              >
                Ưu Điểm
              </Link>
            </Typography>
            <Typography variant="h6" component="h2" className={classes.title}>
              <Link
                to="share"
                spy={true}
                smooth={true}
                offset={-67}
                className={classes.link}
              >
                Share
              </Link>
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
    </header>
  );
}
