import React from "react";
import Particles from "react-particles-js";
import icon1 from "../../../../media/landing-pages/lp_banner_icon-1.png";
import icon2 from "../../../../media/landing-pages/lp_banner_icon-2.png";
import icon3 from "../../../../media/landing-pages/lp_banner_icon-3.png";
import icon4 from "../../../../media/landing-pages/lp_banner_icon-4.png";
import icon5 from "../../../../media/landing-pages/lp_banner_icon-5.png";
import icon6 from "../../../../media/landing-pages/lp_banner_icon-6.png";
import icon7 from "../../../../media/landing-pages/lp_banner_icon-7.png";
import icon8 from "../../../../media/landing-pages/lp_banner_icon-8.png";
import icon9 from "../../../../media/landing-pages/lp_banner_icon-9.png";
import icon10 from "../../../../media/landing-pages/lp_banner_icon-10.png";
import icon11 from "../../../../media/landing-pages/lp_banner_icon-11.png";
import icon12 from "../../../../media/landing-pages/lp_banner_icon-12.png";
import icon13 from "../../../../media/landing-pages/lp_banner_icon-13.png";
import icon14 from "../../../../media/landing-pages/lp_banner_icon-14.png";

export default function Particle() {
  return (
    <Particles
      id="particle"
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: "1",
      }}
      params={{
        particles: {
          number: {
            value: 40,
            density: {
              enable: true,
              value_area: 1200,
            },
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 1,
            width: 3,
          },
          move: {
            speed: 1.5,
            out_mode: "out",
            direction: "top-right",
          },
          shape: {
            type: ["image"],
            polygon: {
              nb_sides: 1000,
            },
            image: [
              {
                src: icon1,
                height: 30,
                width: 30,
              },
              {
                src: icon2,
                height: 20,
                width: 20,
              },
              {
                src: icon3,
                height: 20,
                width: 20,
              },
              {
                src: icon4,
                height: 20,
                width: 20,
              },
              {
                src: icon5,
                height: 20,
                width: 20,
              },
              {
                src: icon6,
                height: 20,
                width: 20,
              },
              {
                src: icon7,
                height: 20,
                width: 20,
              },
              {
                src: icon8,
                height: 20,
                width: 20,
              },
              {
                src: icon9,
                height: 20,
                width: 20,
              },
              {
                src: icon10,
                height: 20,
                width: 20,
              },
              {
                src: icon11,
                height: 20,
                width: 20,
              },
              {
                src: icon12,
                height: 20,
                width: 20,
              },
              {
                src: icon13,
                height: 20,
                width: 20,
              },
              {
                src: icon14,
                height: 20,
                width: 20,
              },
            ],
          },
          opacity: {
            value: 1,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 35,
            random: true,
            anim: {
              enable: false,
              speed: 4,
              size_min: 10,
              sync: false,
            },
          },
        },
        retina_detect: true,
      }}
    />
  );
}
