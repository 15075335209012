import React from "react";
import Image from "../../../common/Image";
import Button from "@material-ui/core/Button";
import ALink from "../../../common/Alink"
import TitleRight from "../../common/TitleRight";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#E3F3FC",
  },
  cssCourseIntro: {
    padding: theme.spacing(3, 0, 3, 0),
    margin: "auto",
    maxWidth: "1280px",
  },
  aboutUs: {
    margin: "30px auto",
    display:"flex",
    flexDirection:"column-reverse",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 7% 2%",
    },
  },
  aboutUs_content: {
    margin: "4% 0 0",
    [theme.breakpoints.up("sm")]: {
      margin: "2% 0",
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "6% 0 6% 5%",
    },
  },
  aboutUs_description: {
    margin: theme.spacing(1, 1.25, 1, 1.25),
    textAlign: "justify",
    color: "#747474",
  },
  aboutUs_image: {
    padding: theme.spacing(0, 1.25, 0, 1.25),
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  image: {
    width: "100%",
    height: "230px",
    objectFit: "cover",
    overflow: "hidden",
    transition: "all .3s ease-in-out",
    "@media only screen and (min-width: 400px)": {
      height: "270px",
    },
    "@media only screen and (min-width: 500px)": {
      height: "300px",
    },
    "@media only screen and (min-width: 600px)": {
      height: "350px",
    },
    "@media only screen and (min-width: 768px)": {
      height: "280px"
    },
    "@media only screen and (min-width: 870px)": {
      height: "320px",
    },
    "@media only screen and (min-width: 1024px)": {
      height: "380px",
    },
  },
  link: {
    textDecoration: "none",
  },
  button: {
    [theme.breakpoints.up("sm")]: {
    top:"20px",
    },
    left: "30px",
    height: "42px",
    width: "144px",
    [theme.breakpoints.down("sm")]: {
      width: 110,
      height: 37,
      fontSize: 14,
      margin:"0 28%"
    },
    fontSize: "18px",
    backgroundColor: "#fde314",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#FFBC00",
    },
    transition: "all .3s cubic-bezier(.17,.67,.83,.67)"
  }
}));

export default function AboutUs({ data }) {
  const classes = useStyles();
  return (
    <section className={classes.root} id="css">
      <div className={classes.cssCourseIntro}>
        <TitleRight title="2" label="CSS" />
        <Container className={classes.aboutUs}>
          <div className={classes.aboutUs_content}>
            {data.description.map((item) => (
            <Typography className={classes.aboutUs_description}>
          {item}
            </Typography>
            ))}
            {/* <ALink to={`/courses`} className={classes.link}>
              <Button variant="contained" className={classes.button} id="button">
              {data.button.title}
              </Button>
            </ALink> */}
          </div>
          <div className={classes.aboutUs_image}>
            <Image className={classes.image} {...data.image} />
          </div>
          </Container>
      </div>
    </section>
  );
}
