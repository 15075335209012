import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Container, GridListTileBar, Typography } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import { StarRate } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Image from "../../common/Image";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: "3.5rem",
    },
    slideTitle: {
      padding: theme.spacing(6, 0, 3, 0),
    },
    slideDescription: {
      opacity: "0.8",
      textAlign: "justify",
      padding: theme.spacing(0, 1, 1, 1),
      marginBottom: "20px",
      [theme.breakpoints.up("sm")]:{
        textAlign: "center"
      }
    },

    slidePaper: {
      display: "flex",
      marginLeft: "-10px",
      position: "relative ",
      "@media screen and (max-width: 767px)": {
        display: "block",
        marginLeft: "0px",
      },
    },
    slideCard: {
      width: "85%",
      margin: "auto",
      marginBottom: "30px",
      height: 200,
      overflow: "hidden",
      borderRadius: 0,
      "@media screen and (min-width: 767px)": {
        height: 180,
        marginBottom: "0px",
        marginLeft: "10px",
      },
      "@media screen and (min-width: 1024px)": {
        height: 255,
      },
    },
    img: {
      height: 200,
      width: "100%",
      objectFit: "cover",
      "@media screen and (min-width: 768px)": {
        height: 180,
      },
      "@media screen and (min-width: 1024px)": {
        height: 255,
      },
    },
    titleBar1: {
      width: "calc(33.33% - 10px)",
      left: "auto",
      right: "auto",
      height: "60px",
      backgroundColor: "rgb(0 0 0 / 40%)",
      "@media screen and (max-width: 767px)": {
        width: "85%",
        top: "141px",
      },
    },
    titleBar2: {
      width: "calc(33.33% - 10px)",
      left: "auto",
      right: "auto",
      height: "60px",
      backgroundColor: "rgb(0 0 0 / 40%)",
      "@media screen and (max-width: 767px)": {
        width: "85%",
        top: "370px",
      },
    },
    titleBar3: {
      width: "calc(33.33% - 10px)",
      left: "auto",
      right: "auto",
      height: "60px",
      backgroundColor: "rgb(0 0 0 / 40%)",
      "@media screen and (max-width: 767px)": {
        width: "85%",
        top: "600px",
      },
    },
    label: {
      fontSize: "1.3rem",
      "@media screen and (min-width: 767px)": {
        fontSize: "20px",
      },
      "@media screen and (min-width: 1024px)": {
        fontSize: "25px",
        paddingLeft: "15px",
      },
    },
    actionIcon: {
      paddingRight: "10px",
      paddingTop: "5px",
    },
    nextButton: {
      position: "absolute ",
      top: "50%",
      transform: "translateY(-50%)",
      right: "0px",
    },
    backButton: {
      position: "absolute ",
      top: "50%",
      transform: "translateY(-50%)",
      left: "8px",
    },
    link: {
      textDecoration: "none",
    },
    button: {
      display: "flex",
      margin: "40px auto",
      height: "50px",
      width: "220px",
      fontSize: "20px",
      backgroundColor: "#fde314",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#FFBC00",
      },
    },
    starIcon: {
      color: yellow[500],
      fontSize: "55px",
      "@media screen and (max-width: 1023px)": {
        fontSize: "45px",
      },
    },
  })
);

// TODO: Có thể tạo SlideItem component
export default function OutstandingCourse(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.data.slide.length - 2;
  const desktopViewport = useMediaQuery("(min-width:768px)");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <section id="courses" >
      <Container className={classes.root}>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          color="primary"
          className={classes.slideTitle}
        >
          {props.data.title}
        </Typography>
        <Typography className={classes.slideDescription}>
          {props.data.description}
        </Typography>
        <Paper elevation="0" className={classes.slidePaper} id="course-item">
          <Paper className={classes.slideCard}>
            <Image
              className={classes.img}
              src={props.data.slide[activeStep].imgPath}
              alt={props.data.slide[activeStep].label}
            />
            <GridListTileBar
              title={props.data.slide[activeStep].label}
              classes={{
                root: classes.titleBar1,
                title: classes.label,
                actionIcon: classes.actionIcon,
              }}
              actionIcon={<StarRate className={classes.starIcon} />}
            />
          </Paper>
          <Paper className={classes.slideCard}>
            <Image
              className={classes.img}
              src={props.data.slide[activeStep + 1].imgPath}
              alt={props.data.slide[activeStep].label}
            />
            <GridListTileBar
              title={props.data.slide[activeStep + 1].label}
              classes={{
                root: classes.titleBar2,
                title: classes.label,
                actionIcon: classes.actionIcon,
              }}
              actionIcon={<StarRate className={classes.starIcon} />}
            />
          </Paper>
          <Paper className={classes.slideCard}>
            <Image
              className={classes.img}
              src={props.data.slide[activeStep + 2].imgPath}
              alt={props.data.slide[activeStep].label}
            />
            <GridListTileBar
              title={props.data.slide[activeStep + 2].label}
              classes={{
                root: classes.titleBar3,
                title: classes.label,
                actionIcon: classes.actionIcon,
              }}
              actionIcon={<StarRate className={classes.starIcon} />}
            />
          </Paper>
          {/* {desktopViewport?activeStep===0?<Button className={classes.backButton} disabled><KeyboardArrowLeft style={{ fontSize: 60}}/></Button>
          :<Button className={classes.backButton} onClick={handleBack}><KeyboardArrowLeft style={{ fontSize: 60}}/></Button>:null
        }
        {desktopViewport?activeStep===(maxSteps-1)?<Button className={classes.nextButton} disabled><KeyboardArrowRight style={{ fontSize: 60}}/></Button>
          :<Button className={classes.nextButton} onClick={handleNext}><KeyboardArrowRight style={{ fontSize: 60}}/></Button>:null
        } */}
        </Paper>
      </Container>
    </section>
  );
}
