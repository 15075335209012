import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import React from "react";
import Alink from "../../../common/Alink";
import Image from "../../../common/Image";
import Typography from "@material-ui/core/Typography";
import CardMeta from "../../../blog/sections/common/CardMeta"

const useStyles = makeStyles((theme) => ({
    blogItem: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        position: "relative",
        flexDirection: "row",
    },
    cardItem: {
        maxWidth: "280px",
        height: "auto",
        marginBottom: "8%",
        "&:hover": {
            transform: "translateY(-3px)",
            boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
          },
        [theme.breakpoints.up("md")]: {
            marginBottom: "5%",
            maxWidth: "300px"
        },
    },
    linkItem: {
        textDecoration: "none",
    },
    image: {
        width: "100%",
        height: "180px",
        objectFit: "cover",
        [theme.breakpoints.up("md")]: {
            height: "200px",
        },
    },
    subTitle: {
        display: "-webkit-box",
        boxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: "2",
        margin: "5%",
        fontWeight: "bold",
        "&:hover": {
            color: "#1f65b7",
          },
    },
}))

export default function BlogItems({ data }) {
    const classes = useStyles();

    return (
        <section className={classes.blogItem}>
            {data.slice(0, 3).map((item, id) => {
                return (
                    <Card key={id} className={classes.cardItem}>
                        <Alink to={`/blog/${item.id}`} className={classes.linkItem}>
                            <Image
                                className={classes.image}
                                src={item.img.src}
                                alt={item.img.alt} />
                            <Typography variant="body2" color="primary" className={classes.subTitle}>{item.news}</Typography>
                        </Alink>
                        <CardMeta id={item.id} news={item.news} />
                    </Card>
                )
            })}
        </section>
    )
}
