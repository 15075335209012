import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Particle from "./Particle";
import bgImage from "../../../../media/landing-pages/lp_banner.jpeg";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  ldpageHeader: {
    margin: 0,
    padding: 0,
    height: "calc((var(--vh, 1vh) * 100) - 48px)",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    background: `url(${bgImage}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: " center",
    width: "100%",
  },
  ldpageHeader_content: {
    position: "absolute",
    background: "rgba(0, 0, 0, 0.6)",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    zIndex: "111",
  },
  ldpageHeader_content_text: {
    position: "absolute",
    padding: "30px",
    textAlign: "justify",
    [theme.breakpoints.up("sm")]: {
      padding: "60px",
    },
    [theme.breakpoints.up("md")]: {
      left: "5%",
      width: "90%",
      textAlign: "left",
    },
  },
  ldpageHeader_title: {
    color: "#fff",
    textAlign: "center",
    letterSpacing: "3px",
    marginBottom: theme.spacing(4),
  },
  ldpageHeader_description: {
    color: "#fff",
    letterSpacing: "3px",
    fontWeight: "500",
    textAlign: "center",
    lineHeight: "1.4",
    fontSize: "1.4rem",
    fontStyle: "italic",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.2rem",
    },
    "& > span": {
      fontWeight: "600",
      boxShadow: "inset 0 -0.175em #FDE313, inset 0 -0.2em #FDE313",
    },
  },
}));

//page header viewport height set vh
if (typeof window !== "undefined") {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
}
export default function Banner({ data }) {
  const classes = useStyles();
  return (
    <section className={classes.ldpageHeader} id="header">
      <Particle />
      <div className={classes.ldpageHeader_content}>
        <div className={classes.ldpageHeader_content_text}>
          <Typography
            variant="h2"
            component="h1"
            color="primary"
            dangerouslySetInnerHTML={{ __html: data.title }}
            className={classes.ldpageHeader_title}
          />
          <Typography
            variant="h3"
            component="h3"
            color="primary"
            dangerouslySetInnerHTML={{ __html: data.description }}
            className={classes.ldpageHeader_description}
          />
        </div>
      </div>
    </section>
  );
}
