import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Container, Typography } from "@material-ui/core";
import { FacebookShareButton } from "react-share";
import FacebookIcon from "@material-ui/icons/Facebook";
import background from "../../../../media/landing-pages/lp_share.png";

const useStyles = makeStyles((theme) => ({
  share: {
    margin: "auto",
    height: "500px",
    width: "100%",
    backgroundImage: ` url("${background}")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: " flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      padding: "0 5%",
      width: "80%",
      height: "550px",
    },
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    color: "white",
    marginBottom: theme.spacing(5),
  },
  description: {
    color: "white",
    fontWeight: "500",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      margin: "0 5rem 2rem",
    },
  },
  shareButtonList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(-2),
  },
  shareButton: {
    boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    },
    width: "180px",
    padding: "5px !important",
    backgroundColor: "rgba(0,0,0,0.5) !important",
    borderRadius: "8px !important",
    outline: "none !important",
    marginLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      color: "#fff",
      marginTop: "2px",
      fontWeight: "bold",
      fontSize: "20px",
      marginLeft: theme.spacing(1),
    },
  },
  icon: {
    fontSize: "40px",
  },
}));

function Share(props) {
  const classes = useStyles();
  return (
    <section className={classes.share} id="share">
      <Container className={classes.container}>
        <Typography
          component="h2"
          variant="h4"
          align="center"
          className={classes.title}
        >
          Share
        </Typography>
        <Typography className={classes.description} align="center">
          Chia sẻ với bạn bè để cùng nhau học tập tốt, phát triển và hoàn thành
          ước mơ!
        </Typography>
        <div className={classes.shareButtonList}>
          <FacebookShareButton
            url={"https://it-dojo.world-works.co.jp/"}
            quote={"Học lập trình tại World IT Dojo  "}
            hashtag={"#WorldITDojo"}
            description={"Học lập trình tại World IT Dojo"}
            className={classes.shareButton}
          >
            <FacebookIcon style={{ color: "#fff" }} className={classes.icon} />
            <span>Facebook</span>
          </FacebookShareButton>
        </div>
      </Container>
    </section>
  );
}

export default Share;
