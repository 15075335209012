import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

import TitleLeft from "../../common/TitleLeft";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme) => ({
  html_intro: {
    paddingBottom: theme.spacing(8),
  },
  title: {
    padding: theme.spacing(6, 0, 5, 0),
    color: "#488be9",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6, 5, 5, 5),
    },
  },
  description: {
    textAlign: "center",
    padding: theme.spacing(0, 2, 1, 2),
    marginBottom: "20px",
    "& > span": {
      fontWeight: "bold",
    },
  },

  listItem: {
    margin: "0 auto",
    padding: theme.spacing(4, 1, 0, 1),
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    opacity: "0.8",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginLeft: theme.spacing(-3),
    },
  },
  item: {
    flexDirection: "column",
    height: "100%",
    position: "relative",
    "&:not(:last-of-type)": {
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    "&:not(:last-of-type):before": {
      content: `''`,
      width: "70%",
      height: "1px",
      left: "50%",
      bottom: "0",
      position: "absolute",
      backgroundColor: "#000",
      transform: "translateX(-50%)",
    },
    [theme.breakpoints.up("md")]: {
      "&:not(:last-of-type):before": {
        display: "none",
      },
      width: "33.33%",
      marginLeft: theme.spacing(3),
      paddingBottom: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  item_wrap: {
    backgroundColor: "#FF89A4",
    borderRadius: "50%",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  item_icon: {
    fontSize: "60px",
    backgroundColor: "#FF89A4",
    color: "#fff",
    borderRadius: "inherit",
  },
  items_description: {
    paddingTop: theme.spacing(3),
  },
}));

export function HtmlCourseIntro({ data }) {
  const classes = useStyles();
  return (
    <section id="html-intro" className={classes.html_intro}>
      <Container>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          className={classes.title}
        >
          {data.title}
        </Typography>

        <Typography variant="body1" className={classes.description} dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g, '<br />') }}>
        </Typography>

        <TitleLeft title="1" label="HTML" />

        <List className={classes.listItem}>
          {data.items.map((item, index) => (
            <ListItem kye={index} className={classes.item}>
              <div className={classes.item_wrap}>
                <Icon className={classes.item_icon}>{item.icon}</Icon>
              </div>

              <Typography
                component="body1"
                align="center"
                className={classes.items_description}
              >
                {item.description}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Container>
    </section>
  );
}
