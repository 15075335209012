import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import React from "react";
import Typography from "@material-ui/core/Typography";
import helpLP from "../../../../media/landing-pages/lp_contact.jpg";
import Alink from "../../../common/Alink";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 100%), url(${helpLP})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "300px",
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      height: "400px",
    },
  },
  containerHelp: {
    color: "#ffffff",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  titleHelp: {
    color: "#ffffff",
    marginBottom: theme.spacing(3),
  },
  descriptionHelp: {
    marginBottom: theme.spacing(4),
    fontWeight: "bold",
    padding: "0 10%",
    [theme.breakpoints.up("sm")]: {
      padding: "0",
    },
  },
  linkHelp: {
    textDecoration: "none",
  },
  button: {
    height: "42px",
    width: "170px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: 37,
      fontSize: 14,
    },
    fontSize: "18px",
    backgroundColor: "#fde314",
    color: "#488be9",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#488be9",
      color: "#fff",
    },
    transition: "all .3s cubic-bezier(.17,.67,.83,.67)",
  },
}));

export default function Help({ data }) {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container className={classes.containerHelp}>
        <Typography variant="h4" className={classes.titleHelp}>
          {data.title}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={classes.descriptionHelp}
        >
          {data.description}
        </Typography>
        <Alink to={`/help`} className={classes.linkHelp}>
          <Button className={classes.button}>Liên hệ ngay</Button>
        </Alink>
      </Container>
    </section>
  );
}
