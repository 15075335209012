import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import ALink from "../../../common/Alink";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    position: "fixed",
    bottom: "40px",
    right: "20px",
    height: "42px",
    width: "144px",
    [theme.breakpoints.down("sm")]: {
      width: 110,
      height: 37,
      fontSize: 14,
      right: 10,
    },
    fontSize: "18px",
    textTransform: "none",
    zIndex: 112,
    backgroundColor: "#fde314",
    color: "#488be9",
    "&:hover": {
      backgroundColor: "#488be9",
      color: "#fff",
    },
    transition: "all .3s cubic-bezier(.17,.67,.83,.67)",
  },
  link: {
    textDecoration: "none",
  },
}));

function toggleHomeButtonVisibility() {
  if (!window) return;

  const button = document.getElementById("button");
  const deviceHeight = window.innerHeight;
  const limit = document.documentElement.scrollHeight;

  window.addEventListener("scroll", function (event) {
    if (this.scrollY >= limit - deviceHeight - 200) {
      button.style.opacity = "0";
    } else {
      button.style.opacity = "1";
    }
  });
}

export default function ButtonLP() {
  const classes = useStyles();

  useEffect(() => {
    toggleHomeButtonVisibility();
  }, []);

  return (
    <ALink to={`/courses`} className={classes.link}>
      <Button variant="contained" className={classes.button} id="button">
        Khóa học
      </Button>
    </ALink>
  );
}
