import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "relative",
    right: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#fff",
    paddingTop:"6%",
    [theme.breakpoints.up("sm")]: {
      paddingTop:"4%",
      right: theme.spacing(2),
    },
  },
  number: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFB6CA",
    padding: theme.spacing(4),
    borderRadius: "50%",
    position: "relative",
    zIndex: "1",
  },
  name: {
    position: "absolute",
    right: theme.spacing(5),
    backgroundColor: "#FF89A4",
    padding: theme.spacing(1, 5, 1, 9),
    "&:before": {
      content: `""`,
      position: "absolute",
      top: theme.spacing(-1),
      left: "-20%",
      height: theme.spacing(7),
      width: theme.spacing(8),
      backgroundColor: "#E3F3FC",
      zIndex: 2,
      transform: "skew(-50deg)",
    },
  },
}));

export default function TitleLeft(props) {
  const classes = useStyles();
  return (
    <Typography component="h2" variant="h6" className={classes.title}>
      <span className={classes.name}> {props.label}</span>
      <span className={classes.number}>{props.title}</span>
    </Typography>
  );
}
