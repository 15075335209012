import React from "react"
import SEO from "../components/shell/seo"
import Layout from "../components/shell/layout"
import { graphql } from "gatsby"
import LandingPage from "../components/landingPages/pageContents/LandingPage"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Bắt đầu học lập trình tại World IT Dojo" />
    <LandingPage
      data={data.landingPageJson}
      blogData={data.allBlogJson.nodes}
      topData={data.topPageJson}
    />
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    landingPageJson {
      Banner {
        description
        title
        image {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
      Help {
        description
        title
      }
      Advantages {
        title
        items {
          description
          title
          image {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
      CssCourseIntro {
        description
        image {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
        button {
          title
        }
      }
      HtmlCourseIntro {
        description
        title
        items {
          description
          icon
        }
      }
      JavaScriptIntro {
        title
        description
      }
    }
    allBlogJson(sort: { fields: order, order: DESC }) {
      nodes {
        author
        tags
        content
        date
        id
        news
        order
        summary
        title
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
    }
    topPageJson {
      outstandingCourse {
        description
        title
        slide {
          label
          imgPath {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
    }
  }
`
