import React from "react"

import Banner from "../sections/landing-page/Banner"
import { makeStyles } from "@material-ui/core/styles"
import { Paper } from "@material-ui/core"
import Advantages from "../sections/landing-page/Advantages"
import NavBar from "../sections/landing-page/NavBar"
import Share from "../sections/landing-page/Share"
import { HtmlCourseIntro } from "../sections/landing-page/HtmlCourseIntro"
import CssCourseIntro from "../sections/landing-page/CssCourseIntro"
import ButtonLP from "../sections/landing-page/ButtonLP"
import Blog from "../sections/landing-page/Blog"
import Help from "../sections/landing-page/Help"
import OutstandingCourse from "../common/OutstandingCourse"
import JavaScriptIntro from "../sections/landing-page/JavaScriptIntro"

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: "477px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "233px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "233px",
    },
    "@media screen and (max-width: 356px)": {
      paddingBottom: "496px",
    },
  },
  link: {
    textDecoration: "none",
  },
  button: {
    position: "fixed",
    bottom: "40px",
    right: "45px",
    height: "42px",
    width: "150px",
    fontSize: "18px",
    zIndex: 112,
    backgroundColor: "#fde314",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#FFBC00",
    },
    "@media screen and (max-width: 767px)": {
      fontSize: "14px",
      height: "38px",
      width: "120px",
      bottom: "3%",
      right: "25px",
    },
  },
}))
export default function LandingPage({ data, blogData, topData }) {
  const classes = useStyles()
  return (
    <Paper className={classes.container}>
      <ButtonLP />
      <NavBar />
      <Banner data={data.Banner} />
      {/* <HtmlCourseIntro data={data.HtmlCourseIntro} />
      <CssCourseIntro data={data.CssCourseIntro} /> */}
      <JavaScriptIntro data={data.JavaScriptIntro} />
      <OutstandingCourse data={topData.outstandingCourse} />
      <Blog data={blogData} />
      <Help data={data.Help} />
      <Advantages data={data.Advantages} />
      <Share />
    </Paper>
  )
}
