import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import Typography from "@material-ui/core/Typography";
import BlogItems from "./BlogItems";
import Alink from "../../../common/Alink";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  blog: {
    padding: "0px 10px 40px",
    [theme.breakpoints.up("md")]: {
      padding: "0px 10px 70px"
    }
  },
  blogTitle: {
    display: "flex",
  },
  blogIcon: {
    color: "#fde314",
    width: "50px",
    height: "50px",
    [theme.breakpoints.up("sm")]: {
      width: "80px",
      height: "80px",
    },
  },
  title: {
    paddingLeft: "15px",
    margin: "auto 0",
  },
  blogDescription: {
    opacity: "0.8",
    padding: "15px 0",
    [theme.breakpoints.up("sm")]: {
      padding: "15px 0 30px",
    },
  },
  blogItems: {
    padding: "8%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.up("sm")]: {
      padding: "8% 5%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "5%",
    },
  },
  linkBlog: {
    textDecoration: "none",
  },
  buttonBlog: {
    height: "42px",
    width: "170px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: 37,
      fontSize: 14,
    },
    fontSize: "18px",
    backgroundColor: "#fde314",
    color: "#488be9",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#488be9",
      color: "#fff",
    },
    transition: "all .3s cubic-bezier(.17,.67,.83,.67)",
  },
}));

export default function Blog({ data }) {
  const classes = useStyles();
  return (
    <section id="news" className={classes.blog}>
      <Container>
        <Grid className={classes.blogTitle}>
          <TrendingUpIcon className={classes.blogIcon} />
          <Typography variant="h4" color="primary" className={classes.title}>
            Tin tức
          </Typography>
        </Grid>
        <Typography variant="body1" className={classes.blogDescription}>
          Nhiều tin tức về Công Nghệ và các phương pháp học Lập Trình hiệu quả,
          liên tục được cập nhật.
        </Typography>
        <Grid className={classes.blogItems}>
          <BlogItems data={data} />
          <Grid container direction="row" justify="center">
            <Alink to={`/blog`} className={classes.linkBlog}>
              <Button className={classes.buttonBlog}>Xem thêm</Button>
            </Alink>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
